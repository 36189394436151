import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const SelectLanguage = (props: { langs: any[] }) => {
    const links = props.langs.map(
        (lang: {
            langKey: React.ReactText
            link: string
            selected: boolean
        }) => (
            <Link
                className="navbar-item has-text-weight-normal"
                key={lang.langKey}
                to={lang.link}
                alt={lang.langKey}
            >
                {lang.selected === true ? 'KA' : 'EN'}
            </Link>
        )
    )
    const dropdown = props.langs.map(
        (checker: {
            selected: boolean
            langKey: React.ReactText
            link: string
        }) =>
            checker.selected === true ? (
                links[0]
            ) : (
                <Link
                    className="navbar-item has-text-weight-normal"
                    key={checker.langKey}
                    to={checker.link}
                >
                    KA
                </Link>
            )
    )

    return dropdown[1]
}

SelectLanguage.propTypes = {
    langs: PropTypes.array,
}

export default SelectLanguage
