module.exports = {
    Language: 'EN',
    tags: 'ტეგები',
    'tags.allTagsLink': 'ყველა თეგის დათვალიერება',
    'tags.intro': 'აქ ნახავთ ყველა თეგს',
    'tags.blog.intro': 'ბლოგის თეგები',
    'tags.pages.intro': 'გვერდის თეგები',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      ერთი {post}
      სხვა {posts}
    } ტეგებით`,
    cookies:
        'ქუქი ფაილები გვეხმარება, ჩვენი სერვისების განხორციელებაში. ჩვენი სერვისების გამოყენებით, თქვენ ეთანხმებით ქუქის გამოყენებას.',
    'cookies.policy': 'ქუქის პოლიტიკა',
    'cookies.accept': 'თანხმობა',
    header: 'მენიუ',
    'header.services': 'სერვისები',
    'header.aboutus': 'პორტფოლიო',
    'header.blog': 'ბლოგი',
    'header.language': 'EN',
    'header.contactus': 'კონტაქტი',
    subscribe: 'გამოწერა',
    'subscribe.name': 'სახელი',
    'subscribe.email': 'ელ.ფოსტა',
    'subscribe.submit': 'გაგზავნა',
    home: 'მთავარი',
    'home.hero.title': 'თქვენი კავშირი ლოგისტიკის სამყაროსთან',
    'home.hero.subtitle':
        "ჩვენ ვამარტივებთ ლოგისტიკას, რაც ზრდის ბიზნესის პროდუქტიულობას და ამარტივებს მომხმარებლის ცხოვრებას.",
    'home.hero.vision':
    'ბიზნესის წარმატებისთვის აუცილებელია ლოგისტიკური პროცესების ეფექტურად მართვა. ითანამშრომლეთ 4BL-ის შედეგებზე ორიენტირებულ პროფესიონალებთან გამარტივებული ოპერაციებისა და მომხმარებლებზე მორგებული გადაწყვეტილებების მისაღებად.',
    'home.hero.cta': 'დაგვიკავშირდით',
    'home.services.title': 'სერვისები',
    'home.hire.title': 'რატომ ჩვენ?',
    'home.hire.subtitle':
        'ჩვენ ვქმნით შესაძლებლობებს, რომლებიც აუმჯობესებს ჩვენი მომხმარებლების ჩართულობას და მომგებიანობას. ყოველდღე ვიღებთ მნიშვნელოვან ბიზნეს გამოწვევებს, რომლებიც აუმჯობესებს თქვენს ღირებულებას!',
    'home.hire.cta': 'კონტაქტი',
    'home.works.title': 'პორტფოლიო',
    'home.works.subtitle':
        "ჩვენ გვიყვარს ის, რასაც ვაკეთებთ, გვიყვარს ციფრული სამყარო! ჩვენ ვთანამშრომლობთ თქვენთან მნიშვნელოვანი შედეგების მისაღწევად.",
    'home.getwork.title': "მოდი ვისაუბროთ!",
    'home.getwork.subtitle':
        'ახალი პროექტი ჰორიზონტზე?',
    services: 'სერვისები',
    'services.roll.title': 'რას ვაკეთებთ ჩვენ',
    'services.roll.subtitle':
        'ჩვენ ვამარტივებთ ლოგისტიკას, რაც ზრდის ბიზნესის პროდუქტიულობას და ამარტივებს მომხმარებლის ცხოვრებას.',
    'services.roll.cta': 'გაიგე მეტი →',
    'services.hire.cta': 'კონტაქტი',
    blog: 'ბლოგი',
    'blog.submit': 'გაგზავნა',
    'blog.roll.title': 'დამფუძნებლის მოგზაურობა',
    'blog.roll.subtitle':
        'წაიკითხეთ რეალური გამოცდილება, სირთულეები თუ როგორ გამოიყურება წარმატებისკენ მიმავალი გზა Optimal Digital – ის გუნდისგან.',
    'blog.keep.reading': 'განაგრძე კითხვა →',
    'blog.time.read': 'წუთი წასაკითხად',
    'blog.post.view': 'ნახვა',
    'blog.post.newsletter.first': 'მოგეწონა ბლოგი? გახდი ჩვენი გამომწერი.',
    'blog.post.newsletter.second':
        'მზად ხარ მოგზაურობისთვის? გახდი ჩვენი გამომწერი.',
    contact: 'კონტაქტი',
    'contact.title': 'გაქვთ იდეა?',
    'contact.name': 'სახელი',
    'contact.email': 'ელ.ფოსტა',
    'contact.number': 'ტელეფონის ნომერი',
    'contact.message': 'ტექსტი',
    'contact.send': 'გაგზავნა',
    not_found: '404 - გვერდი არ მოიძებნა',
    404: 'თქვენ მოხვდით უმისამართ გვერდზე...',
    work: 'Work',
    'work.roll.title': 'რა გავაკეთეთ',
    'work.roll.subtitle':
        'ჩვენ ვქმნით ციფრული გადაწყვეტის გზებს, რომელიც ზრდის პროდუქტიულობას ბიზნესისთვის და ამარტივებს მომხმარებლის ცხოვრებას.',
    'work.case.cta': 'ვრცლად →',
    'work.hire.arrow.cta': 'კონტაქტი →',
    'work.post.location': 'ადგილმდებარეობა',
    'work.post.date': 'თარიღი',
    'work.post.timeline': 'ვადები',
    'work.post.team': 'გუნდი',
    'work.post.features': 'მახასიათებლები',
    'work.post.services': 'სერვისები',
    'work.post.tools': 'ტექნოლოგიები',
    'work.post.hireus': 'კონტაქტი',
    footer: 'Footer',
    'footer.title.services': 'მომსახურებები',
    'footer.title.company': 'კომპანია',
    'footer.title.links': 'ბმულები',
    'footer.title.follow': 'მოგვყევი',
    'footer.link.privacy': 'კონფიდენციალურობის პოლიტიკა',
    'footer.link.cookie': 'ქუქის პოლიტიკა',
    'footer.link.technologies': 'ტექნოლოგიები',
    'footer.link.blog': 'ბლოგი',
    'footer.link.services': 'სერვისები',
    'footer.link.aboutus': 'პორტფოლიო',
    'footer.link.blockchain-development': 'ბლოქჩეინის დამზადება',
    'footer.link.web-development': 'საიტის დამზადება',
    'footer.link.app-development': 'საჰაერო ⛴',
    'footer.link.road': 'სახმელეთო ⛟',
    'footer.link.IoT': 'ნივთების ინტერნეტი',
    'footer.link.brokerage': 'საბროკერო ✎',
    'footer.link.submit': 'გაგზავნა',
    copyright: '© 2024 4 ბი-ელ. შექმნილია ♥︎ თბილისიდან.',
}
