module.exports = {
    Language: 'EN',
    tags: 'Tags',
    'tags.allTagsLink': 'Browse all tags',
    'tags.intro': 'Here you can find all the tags',
    'tags.blog.intro': 'Here you can find all the blog tags',
    'tags.pages.intro': 'Here you can find all the tags in the pages',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
    cookies:
        'Cookies help us deliver our services. By using our services, you agree to our use of cookies.',
    'cookies.policy': 'Cookies Policy',
    'cookies.accept': 'Accept',
    header: 'menu',
    'header.services': 'Services',
    'header.aboutus': 'About Us',
    'header.blog': 'Blog',
    'header.language': 'EN',
    'header.contactus': 'Contact us',
    subscribe: 'Subscribe',
    'subscribe.name': 'Name',
    'subscribe.email': 'Email',
    'subscribe.submit': 'Submit',
    home: 'Home',
    'home.hero.title': 'Your connection to the logistic world',
    'home.hero.subtitle':
        "We optimize logistics to boost business productivity and simplify customers' lives.",
    'home.hero.cta': "Send Your Order Here",
    'home.services.title': 'Services',
    'home.hire.title': 'Why you hire us?',
    'home.hire.subtitle':
        'At 4BL, we are dedicated to delivering results. With our expertise, we tackle logistics challenges head-on, enhancing your engagement and profitability with every solution we provide!',
    'home.hero.vision':
    'Effective logistics solutions are integral to achieving business success. Collaborate with impact-driven professionals at 4BL for streamlined operations and customer-centric solutions.',
    'home.hire.cta': 'Contact us',
    'home.works.title': 'Featured works',
    'home.works.subtitle':
        "We love what we're doing, We love digital! We collaborate with you to deliver meaningful results.",
    'home.getwork.title': "Let's get to work!",
    'home.getwork.subtitle':
        'Embarking on a new project? Our logistics experts are here to help you navigate the journey!',
    services: 'Services',
    'services.roll.title': 'What we do',
    'services.roll.subtitle':
        'We specialize in logistics solutions that boost productivity for businesses and simplify the lives of our customers.',
    'services.roll.cta': 'Learn More →',
    'services.hire.cta': 'Contact us',
    blog: 'Blog',
    'blog.submit': 'Submit',
    'blog.roll.title': 'Our Journey',
    'blog.roll.subtitle':
        'Read real experiences, challenges, and what the road to success looks like from the team behind 4BL.',
    'blog.roll.subscribe.placeholder': 'Enter your email to subscribe',
    'blog-message': 'Last News',
    'blog.keep.reading': 'Keep reading →',
    'blog.time.read': 'min read',
    'blog.post.view': 'views',
    'blog.post.newsletter.first': 'Like What You Read? Join our subrcribers.',
    'blog.post.newsletter.second': 'Ready for more? Join our subrcribers.',
    'find-out-more': 'Find out more...',
    contact: 'Contact',
    'contact.title': "Let's work together",
    'contact.name': 'Name',
    'contact.email': 'Email',
    'contact.number': 'Mobile Number',
    'contact.message': 'Message',
    'contact.send': 'Send',
    not_found: '404 - Page Not Found',
    404: 'You are arrived to an unvailable page or you have missed the path...',
    work: 'Work',
    'work.roll.title': 'What we have done',
    'work.roll.subtitle':
        'We build software that boosts productivity for businesses and simplifies customers’ lives.',
    'work.case.cta': 'View Case Study →',
    'work.hire.arrow.cta': 'Contact us →',
    'work.post.location': 'Location',
    'work.post.date': 'Date',
    'work.post.timeline': 'Timeline',
    'work.post.team': 'Team',
    'work.post.features': 'Features',
    'work.post.services': 'Services',
    'work.post.tools': 'Tools',
    'work.post.hireus': 'Contact us',
    footer: 'Footer',
    'footer.title.services': 'Services',
    'footer.title.company': 'Company',
    'footer.title.links': 'Links',
    'footer.title.follow': 'Follow Us',
    'footer.link.privacy': 'Privacy policy',
    'footer.link.cookie': 'Cookie policy',
    'footer.link.technologies': 'Technologies',
    'footer.link.blog': 'Blog',
    'footer.link.services': 'Services',
    'footer.link.aboutus': 'About us',
    'footer.link.blockchain-development': 'Air ✈︎',
    'footer.link.web-development': 'Domestic 🏗',
    'footer.link.app-development': 'Sea ⛴',
    'footer.link.road': 'Road ⛟',
    'footer.link.IoT': 'Demestic USA',
    'footer.link.brokerage': 'Brokerage ✎',
    'footer.link.submit': 'Submit',
    copyright: '© 2024 4BL LLC. Made with ♥︎ from Tbilisi.',
}
