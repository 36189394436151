import React, { useState } from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import FaSun from '@meronex/icons/fa/FaSun'
import FaMoon from '@meronex/icons/fa/FaMoon'

const ModeToggler = () => {
    const [darkMode, setDarkMode] = useState(false)
    return (
        <ThemeToggler>
            {({ toggleTheme }) => (
                <div
                    onClick={() => {
                        toggleTheme(darkMode ? 'dark' : 'light')
                        setDarkMode(!darkMode)
                    }}
                    className="is-cursor-pointer"
                >
                    <i className="is-moon-icon">
                        <FaMoon />
                    </i>
                    <i className="is-sun-icon">
                        {' '}
                        <FaSun />
                    </i>
                </div>
            )}
        </ThemeToggler>
    )
}

export default ModeToggler
