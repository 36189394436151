import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, injectIntl } from 'react-intl'
import menuTree from '../../data/menuTree'
import select from '../../components/utils'
import FaLinkedin from '@meronex/icons/fa/FaLinkedin'
import FaFacebookSquare from '@meronex/icons/fa/FaFacebookSquare'
import FaTwitterSquare from '@meronex/icons/fa/FaTwitterSquare'

import ScrollToTop from '../../components/ScrollToTop'
import FooterNewsletterForm from '../NewsletterForm/footer-subscribe'
import logoDark from '../../img/Just-Logo.png'
import logoLight from '../../img/Just-Logo-White.png'

class Footer extends React.Component {
    render() {
        const props = this.props
        const sel = select(props.langKey)
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <footer className="footer is-font-secondary">
                <div className="container">
                    <div className="columns is-vcentered is-mobile">
                        <div className="column is-6">
                            <div className="footer-logo">
                                <img
                                    className="is-logo-dark"
                                    src={logoDark}
                                    alt="4BL"
                                />
                                <img
                                    className="is-logo-light"
                                    src={logoLight}
                                    alt="4BL"
                                />
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="is-right-scroll is-flex">
                                <ScrollToTop />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 columns is-multiline">
                        <div className="column is-3">
                            <p className="has-text-weight-medium has-text-footer-header-color">
                                <FormattedMessage id="footer.title.company" />
                            </p>
                            <ul>
                                <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.cookie[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.cookie" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.privacy[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.privacy" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-weight-medium has-text-footer-header-color">
                                <FormattedMessage id="footer.title.links" />
                            </p>
                            <ul>
                                {/* <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.blog[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.blog" />
                                    </Link>
                                </li> */}
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.services[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.services" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    {/* <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.work[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.aboutus" />
                                    </Link> */}
                                </li>
                            </ul>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-weight-medium has-text-footer-header-color">
                                <FormattedMessage id="footer.title.services" />
                            </p>
                            <ul>
                                <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.digitalMarketing[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.road" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.appDevelopment[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.app-development" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.blockchainDevelopment[
                                                sel
                                            ] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.blockchain-development" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.webDevelopment[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.web-development" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang + '/' + menuTree.IoT[sel] + '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.IoT" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang + '/' + menuTree.IoT[sel] + '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.brokerage" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-weight-medium has-text-footer-header-color">
                                <FormattedMessage id="footer.title.follow" />
                            </p>
                            <FooterNewsletterForm />
                            <div className="mt-4">
                                <a
                                    title="facebook"
                                    href="https://www.facebook.com/profile.php?id=61556515910711"
                                >
                                    <FaFacebookSquare
                                        className="mr-2 is-facebook-icon"
                                        size="2.5rem"
                                    />
                                </a>
                                <a
                                    title="twitter"
                                    href="https://twitter.com/optimal_digital"
                                >
                                    <FaLinkedin
                                        className="mx-2 is-linkedin-icon"
                                        size="2.5rem"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="column is-12 social">
                            <div className="is-size-7 has-text-footer-header-color">
                                <FormattedMessage id="copyright" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default injectIntl(Footer)
